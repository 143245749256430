<template>
  <div class="dF fC jSB min-h-screen privacy bh-layout">
    <air-topbar v-if="settings.menuLayoutType !== 'top-dark'" />
		<subbar/>

    <transition :name="settings.routerAnimation" mode="out-in">
      <router-view/>
    </transition>

    <div class="mt-auto pb-5 pt-5">
      <hr>
      <div class="text-med-gray text-center">© {{new Date().getFullYear()}} Bildhive. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AirTopbar from 'bh-mod/components/layout/TopBar'
import Subbar from "@/components/layout/SubBar";

export default {
  name: 'PrivacyLayout',
  computed: mapState(['settings']),
  components:{AirTopbar, Subbar},
  data(){
    return {
      exportedMJML:'',
      sections:[],
      exported:'jlkj,',
    }
  }
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style scoped>
  .privacy{
	  background-color: white;
  }
</style>
<style lang="scss">
  .bh-layout{
    background-position:top center;
	background-repeat: no-repeat;
  }
  .top-bar--fixed{
    background:rgba(255,255,255,0.8)
  }
</style>
