<template>
    <div
        v-if="
            !routerPath.startsWith('/system/') &&
            !routerPath.startsWith('/settings/')
        "
    >
        <div :class="$style.subbar" :style="`background-color: ${$route.path.includes('projects/create') ? 'white' : '#f7f5f9'}`">
            <div class="dF aC mb-0">
                <div
                    :class="$style.breadcrumbs"
                    class="dF aC"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>Super Admin</a-breadcrumb-item>
                        <a-breadcrumb-item style="color: var(--orange)">{{
                            crumbs
                        }}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <div
                    v-if="showSideActions"
                    class="dF ml-auto d-none d-sm-flex"
                    style="gap: 25px"
                >
                    <a-input
                        :placeholder="'Search' + ' ' + `${crumbs}...`"
                        v-model="search"
                        size="large"
                    >
                        <a-icon
                            slot="prefix"
                            type="search"
                            style="color: black"
                        />
                    </a-input>
                    <a-button
						v-if="!routerPath.startsWith('/archived') && !routerPath.startsWith('/domains')"
                        class="aC"
                        type="primary"
                        size="large"
                        @click="createNew"
                    >
                        <a-icon type="plus" />ADD NEW</a-button
                    >
                </div>
                <div
                    v-if="routerPath.includes('/team')"
                    class="dF ml-auto d-none d-sm-flex"
                    style="gap: 25px"
                >
                    <a-button
                        type="primary"
                        size="large"
                        @click="
                            UPDATE_ADD_NEW({
                                key: 'superAdmin',
                                value: true,
                            })
                        "
                    >
                        <a-icon type="plus" />
                        ADD NEW ADMIN
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
    data: function () {
        return {};
    },

    computed: {
        ...mapState(["searchFilter"]),

        search: {
            get() {
                return this.searchFilter;
            },
            set(value) {
                this.UPDATE_SEARCH(value);
            },
        },

        showSideActions() {
            return (
                this.crumbs !== "Team" &&
                ["/projects", "/partners", "/users", "/promocode", "/archived", "/domains", "/sso"].includes(this.routerPath)
            );
        },

        crumbs() {
            return this.$store.state.crumbs;
        },

        routerPath() {
            return this.$route.path;
        },
    },
    methods: {
        ...mapMutations(["UPDATE_SEARCH", "UPDATE_ADD_NEW"]),

        createNew() {
            if (["Users", "Partners", "SSO"].includes(this.crumbs)) {
                this.UPDATE_ADD_NEW({
                    key: this.crumbs.toLowerCase(),
                    value: true,
                });
            } else if (this.crumbs === "Promo Code") {
                this.UPDATE_ADD_NEW({
                    key: "promoCode",
                    value: true,
                });
            } else {
                this.$router.push(`/${this.crumbs.toLowerCase()}/create`);
            }
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss">
</style>
