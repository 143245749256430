<template>
    <div class="w-full h-full dF fC aS">
        <LeftNavBar
            class="f1"
            @opened="clicked"
            :selectedValue="selectedNav"
            :items="sidebarItems"
            :imgSrc="''"
        >
            <SubBar />
        </LeftNavBar>
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
import SubBar from "./SubBar";

export default {
    components: { LeftNavBar, SubBar },

    computed: {
        user() {
            return this.$store.state.user && this.$store.state.user.user;
        },

        selectedNav() {
            const path = this.$route.path.substring(1);
            return "/" + path.split("/")[0];
        },

        sidebarItems() {
            const menu = [
                {
                    label: "Projects",
                    value: "/projects",
                    iconUrl: require("@/assets/sideicons/project.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Users",
                    value: "/users",
                    iconUrl: require("@/assets/sideicons/user.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Partners",
                    value: "/partners",
                    iconUrl: require("@/assets/sideicons/star.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
				{
                    label: "Partner Deals",
                    value: "/partner-deals",
                    iconUrl: require("@/assets/sideicons/clipboard.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
            ];
            if (this.user && this.user.adminRole === "superadmin") {
				menu.push({
                    label: "Team",
                    value: "/team",
                    iconUrl: require("@/assets/sideicons/team.svg"),
                    loading: false,
                    options: [],
                    children: [],
                });

				menu.push({
                    label: "SSO",
                    value: "/sso",
                    iconUrl: require("@/assets/sideicons/sso.svg"),
                    loading: false,
                    options: [],
                    children: [],
                });

                menu.push({
                    label: "Domains",
                    value: "/domains",
                    iconUrl: require("@/assets/sideicons/domain.svg"),
                    loading: false,
                    options: [],
                    children: [],
                });

				menu.push({
                    label: "Promo Code",
                    value: "/promocode",
                    iconUrl: require("@/assets/sideicons/price-tag.svg"),
                    loading: false,
                    options: [],
                    children: [],
                });

				menu.push({
                    label: "Reports",
                    value: "/reports",
                    iconUrl: require("@/assets/sideicons/reports.svg"),
                    loading: false,
                    options: [],
                    children: [],
                });
            }
			menu.push({
				label: "Archived Projects",
				value: "/archived",
				iconUrl: require("@/assets/sideicons/archived-project.svg"),
				loading: false,
				options: [],
				children: [],
			});
            return menu;
        },
    },
    methods: {
        clicked(data) {
            const location = data.value;
            if (location != this.$route.path && location) {
                this.$router.push(location);
            }
            this.updateCrumbs(location);
        },
        updateCrumbs(value) {
            const crumbs = this.sidebarItems.find(
                (item) => item.value === value
            );
			if (crumbs) {
				this.$store.commit("UPDATE_CRUMBS", crumbs.label);
			}
        },
    },
    created() {
        this.updateCrumbs('/'+this.$route.path.substr(1).split("/")[0]);
    },
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
