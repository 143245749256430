var render = function render(){var _vm=this,_c=_vm._self._c;return (
            !_vm.routerPath.startsWith('/system/') &&
            !_vm.routerPath.startsWith('/settings/')
        )?_c('div',[_c('div',{class:_vm.$style.subbar,style:(`background-color: ${_vm.$route.path.includes('projects/create') ? 'white' : '#f7f5f9'}`)},[_c('div',{staticClass:"dF aC mb-0"},[_c('div',{staticClass:"dF aC",class:_vm.$style.breadcrumbs,staticStyle:{"align-items":"center","display":"block !important"}},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_vm._v("Super Admin")]),_c('a-breadcrumb-item',{staticStyle:{"color":"var(--orange)"}},[_vm._v(_vm._s(_vm.crumbs))])],1)],1),(_vm.showSideActions)?_c('div',{staticClass:"dF ml-auto d-none d-sm-flex",staticStyle:{"gap":"25px"}},[_c('a-input',{attrs:{"placeholder":'Search' + ' ' + `${_vm.crumbs}...`,"size":"large"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('a-icon',{staticStyle:{"color":"black"},attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1),(!_vm.routerPath.startsWith('/archived') && !_vm.routerPath.startsWith('/domains'))?_c('a-button',{staticClass:"aC",attrs:{"type":"primary","size":"large"},on:{"click":_vm.createNew}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("ADD NEW")],1):_vm._e()],1):_vm._e(),(_vm.routerPath.includes('/team'))?_c('div',{staticClass:"dF ml-auto d-none d-sm-flex",staticStyle:{"gap":"25px"}},[_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){return _vm.UPDATE_ADD_NEW({
                                key: 'superAdmin',
                                value: true,
                            })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" ADD NEW ADMIN ")],1)],1):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }