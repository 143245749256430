import Vue from "vue";
import Router from "vue-router";
import AuthLayout from "bh-mod/layouts/Auth";
import AppLayout from "bh-mod/layouts/App/";
import PrivacyLayout from "@/layouts/Privacy/privacy";
import store from "@/store";
import { isLogged, getCookie, setCookie } from "bh-mod";

Vue.use(Router);

const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/projects",
			component: AppLayout,
			meta: { authRequired: true, hidden: true },
			children: [
				{
					path: "/projects",
					meta: {
						title: "Projects",
						sidebar: true,
					},
					component: () => import("./views/projects/index"),
				},
				{
					path: "/projects/create",
					meta: {
						title: "Create Project",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/projects/create"),
				},
				{
					path: "/projects/:id",
					meta: {
						title: "Edit Project",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/projects/editProject"),
				},
				{
					path: "/users",
					meta: {
						title: "Users",
						sidebar: true,
					},
					component: () => import("./views/users/index"),
				},
				{
					path: "/users/:id",
					meta: {
						title: "Edit User",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/users/editUser"),
				},
				{
					path: "/sso",
					meta: {
						title: "SSO",
						sidebar: true,
					},
					component: () => import("./views/sso/index"),
				},
				{
					path: "/domains",
					meta: {
						title: "Domains",
						sidebar: true,
					},
					component: () => import("./views/domains/index"),
				},
				{
					path: "/domains/:id",
					meta: {
						title: "Domain Details",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/domains/details"),
				},
				{
					path: "/partners",
					meta: {
						title: "Partners",
						sidebar: true,
					},
					component: () => import("./views/partners/index"),
				},
				{
					path: "/partners/:id",
					meta: {
						title: "Edit Partner",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/partners/editPartner"),
				},
				{
					path: "/partner-deals",
					meta: {
						title: "Partner Deals",
						sidebar: true,
					},
					component: () => import("./views/partnerDeals/index"),
				},
				{
					path: "/team",
					meta: {
						title: "Team",
						sidebar: true,
					},
					component: () => import("./views/team/index"),
				},
				{
					path: "/promoCode",
					meta: {
						title: "Promo Codes",
						sidebar: true,
					},
					component: () => import("./views/promoCode/index"),
				},
				{
					path: "/reports",
					meta: {
						title: "Reports",
						sidebar: true,
					},
					component: () => import("./views/reports/index"),
				},
				{
					path: "/archived",
					meta: {
						title: "Archived Projects",
						sidebar: true,
					},
					component: () => import("./views/projects/archived"),
				},
				{
					path: "/promoCode/:id",
					meta: {
						title: "Edit Promo Code",
						sidebar: true,
						sidebarCollapsed: true,
					},
					component: () => import("./views/promoCode/editPromoCode"),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: "/settings",
			redirect: "/settings/accounts",
			component: AppLayout,
			meta: { authRequired: true, hidden: true },
			children: [
				{
					path: "/settings/accounts",
					meta: {
						title: "Account Profile",
						sidebar: true,
						simpleTopbar: true,
					},
					component: () => import("./views/settings/accounts"),
				},
			],
		},
		{
			path: "/system",
			component: AuthLayout,
			redirect: "/system/login",
			children: [
				{
					path: "/system/login",
					meta: {
						title: "Login",
						redirectAuth: true,
					},
					component: () => import("./views/system/login"),
				},
				{
					path: "/system/reset-password",
					meta: {
						title: "Set Up Password",
						redirectAuth: true,
					},
					component: () => import("./views/system/reset"),
				},
				{
					path: "/system/forgot-password",
					meta: {
						title: "Forgot Password",
						redirectAuth: true,
					},
					component: () => import("./views/system/forgot-password"),
				}
			],
		},
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/terms",
					meta: {
						title: "Terms and Conditions",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/privacy",
					meta: {
						title: "Privacy Policy",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: "/system",
			component: PrivacyLayout,
			children: [
				{
					path: "/system/support",
					meta: {
						title: "Support",
						redirectAuthTermsPrivacy: true,
					},
					component: () => import("./views/system/legals"),
				},
			],
		},
		{
			path: '/404',
			component: AuthLayout,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

router.beforeEach(async (to, from, next) => {
	let authRequired = to.matched.some((record) => record.meta.authRequired);
	let redirectAuth = to.matched.some((record) => record.meta.redirectAuth);

	let redirectAuthTermsPrivacy = to.matched.some(
		(record) => record.meta.redirectAuthTermsPrivacy
	);

	let jwt = getCookie("__bhjt");

	if (redirectAuthTermsPrivacy) {
		return next();
	}

	const urlParams = new URLSearchParams(location.search)

	if (urlParams.get('__bhr')) {
		setCookie('__bhr', urlParams.get('__bhr'))
	}

	if (urlParams.get('__bhjt')) {
		setCookie('__bhjt', urlParams.get('__bhjt'))
	}

	if (urlParams.get('__bhi')) {
		setCookie('instance', urlParams.get('__bhi'))
	}

	if (redirectAuth) {
		if (jwt) {
			let logged = await isLogged(jwt, store);
			if (logged) return next("/");
			return next();
		} else return next();
	}

	if (authRequired) {
		if (!store.state.user.user) {
			if (jwt) {
				let logged = await isLogged(jwt, store);

				if (!logged)
					return next({
						path: "/system/login",
						query: { redirect: to.fullPath },
					});
			} else
				return next({
					path: "/system/login",
					query: { redirect: to.fullPath },
				});
		}

		if (
			to.fullPath === "/team" &&
			store.state.user &&
			store.state.user.user &&
			store.state.user.user.adminRole !== "superadmin"
		) {
			return next({
				path: "/",
			});
		}

		return next();
	}
	return next();
});

export default router;
