import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import settings from "./settings";
import { _FP, $api } from "bh-mod";
import moment from "moment";

Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		user,
		settings,
	},
	state: {
		crumbs: "Projects",
		searchFilter: "",
		addNew: {
			projects: false,
			partners: false,
			users: false,
			promoCode: false,
			superAdmin: false,
			sso: false
		},
		fingerPrint: _FP,
		instances: [],
		project: {},
		selectedUser: null,
		selectedPromoCode: null,
		selectedPartner: null,
		theApp: {},
		allSettings: {},
		allUsers: [],
		promoCodes: [],
		packageExtras: [],
		fileStructure: [],
		selectedDomain: null,
		currentStartDate: moment().startOf("month").format("x"),
		currentEndDate: moment().endOf("month").format("x"),
		compareStartDate: null,
		compareEndDate: null,
		pdfOrientation: "portrait",
		paymentMethods: [],
		invoicesToDownload: [],
		downloadingInvoices: false,
		filterDrawer: {
			visible: false,
			filter: {
				paid: true,
				currency: 'USD',
				billingOwner: ['any'],
				project: '',
				partner: '',
				billingName: '',
				invoiceNumber: null,
			},
		},
	},
	mutations: {
		OPEN_FILTER(state) {
			state.filterDrawer.visible = true;
		},
		CLOSE_FILTER(state) {
			state.filterDrawer.visible = false;
		},
		UPDATE_FILTER(state, data) {
			state.filterDrawer.filter = data;
		},
		RESET_DEFAULT_FILTER(state) {
			state.filterDrawer.filter = {
				paid: true,
				currency: 'USD',
				billingOwner: ['any'],
				project: '',
				partner: '',
				billingName: '',
				invoiceNumber: null,
			};
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		UPDATE_CRUMBS(state, data) {
			state.crumbs = data;
		},
		UPDATE_SEARCH(state, data) {
			state.searchFilter = data;
		},
		UPDATE_ADD_NEW(state, { key = "project", value = false }) {
			state.addNew[key] = value;
		},
		CREATE_UPDATE: (state, data) => {
			state.instances.unshift(data);
		},
		SET_SETTINGS: (state, data) => {
			state.allSettings = data;
		},
		SET_INSTANCES: (state, data) => {
			data = data.map((x) => {
				let own = false;
				if (x.owner === state.user.user.id) own = true;
				return {
					...x,
					own,
				};
			});
			state.instances = data;
		},
		SET_PROJECT: (state, data) => {
			state.project = data;
		},
		SET_USER: (state, data) => {
			state.selectedUser = data;
		},
		SET_PROMO_CODE: (state, data) => {
			state.selectedPromoCode = data;
		},
		SET_PARTNER: (state, data) => {
			state.selectedPartner = data;
		},
		SET_DOMAIN: (state, data) => {
			state.selectedDomain = data;
		},
		SET_USERS: (state, data) => {
			state.allUsers = data;
		},
		SET_PROMO_CODES: (state, data) => {
			state.promoCodes = data;
		},
		SET_PACKAGE_EXTRAS: (state, data) => {
			state.packageExtras = data;
		},
		setCurrentDates(state, { startDate, endDate }) {
			state.currentStartDate = startDate;
			state.currentEndDate = endDate;
		},
		setCompareDates(state, { startDate, endDate }) {
			state.compareStartDate = startDate;
			state.compareEndDate = endDate;
		},
		SET_PDF_ORIENTATION(state, data = "portrait") {
			state.pdfOrientation = data;
		},
		SET_PAYMENT_METHODS(state, data = []) {
			state.paymentMethods = data;
		},
		SET_INVOICES(state, data = []) {
			const sortedData = data.sort((a, b) => a.invoiceNumber - b.invoiceNumber);
			state.invoicesToDownload = sortedData
		},
		SET_DOWNLOADING_INVOICES(state, data = false) {
			state.downloadingInvoices = data;
		},
	},
	actions: {
		async FETCH_ALL_USERS({ commit }) {
			try {
				const { data } = await $api.get(`/super-admin/users?_limit=-1`);
				const users = data && data.filter(u => u.email).sort((a, b) => a.email.localeCompare(b.email));
				commit('SET_USERS', users)
			} catch (error) {
				console.error('Error occurred while fetching user list', error)
			}
		},

		async FETCH_ALL_PROMO_CODES({ commit }) {
			try {
				const { data } = await $api.get(`/promo-codes?_publicationState=preview&fetchAll=true&_limit=-1&_sort=createdAt:DESC`);
				const promoCodes = data.map(promoCode => {
					if (!promoCode.endDate) return { ...promoCode, isExpired: false }
					const endMoment = moment(promoCode.endDate);
					const now = moment();
					const isExpired = now.isAfter(endMoment);
					return { ...promoCode, isExpired }
				})
				commit('SET_PROMO_CODES', promoCodes)
			} catch (error) {
				console.error('Error occurred while fetching promo code list', error)
			}
		},
	},
	getters: {},
});
