<template>
    <div id="app">
        <localization></localization>
    </div>
</template>

<script>
import Localization from 'bh-mod/localization'

export default {
    name: "app",
    components: { Localization },
    watch: {
        $route(to) {
            const query = Object.assign({}, to.query);
            this.$store.commit("SETUP_URL_SETTINGS", query);
        },
    },
	methods: {
		async fetchPaymentMethods() {
			try {
				let { data } = await this.$api.get(`/super-admin/payment-methods?_limit=-1`);
				this.$store.commit("SET_PAYMENT_METHODS", data)
			} catch (err) {
				// Handle the error
			}
		}
	},
	created() {
		this.fetchPaymentMethods();
	}
};
</script>
<style lang="scss">
#app {
    min-height: 100vh;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden !important;
}
</style>

<style lang="scss">
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
